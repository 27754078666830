import { z } from 'zod';

export const id = z.string();

export const PropertyImage = z.object({
	cf_id: z.string().optional(),
});

export const PropertyOptions = {
	propertyType: ['Condominium', 'Building', 'Home', 'Lot'],
	areaOfTown: [
		'Puerto Vallarta Centro',
		'Zona Hotelera',
		'Área Francisco Villa',
		'Marina Vallarta',
		'Riviera Nayarit & Costa Norte',
		'Costa Sur & Cabo Corrientes',
		'La Primavera',
	],
	status: ['Sale', 'Rental'],
	currency: ['MXN', 'USD'],
} as const;

export const Property = z.object({
	id,
	propertyType: z.enum(PropertyOptions.propertyType),
	areaOfTown: z.enum(PropertyOptions.areaOfTown),
	slug: z.string().optional(),
	thumbnail: PropertyImage.optional(),
	status: z.enum(PropertyOptions.status),
	price: z.number().min(0),
	currency: z.enum(PropertyOptions.currency),
	lotAreaSquareMeters: z.number().optional(),
	constructionAreaSquareMeters: z.number().optional(),
	bedrooms: z.number().min(0),
	bathrooms: z.number().min(0),
	address: z.string().optional(),
	name: z.string().min(1),
	lat: z.number().optional(),
	lon: z.number().optional(),
	images: z.array(PropertyImage).optional().default([]),
	published: z.boolean().optional().default(false),
	description: z.string().min(1),
	featured: z.boolean().optional().default(false),
});

export type TProperty = z.infer<typeof Property>;

export type DProperty = TProperty & {
	raw_data: string;
};

export interface PropertiesState {
	data: {
		[index: string]: TProperty;
	};
	status: 'idle' | 'loading' | 'failed';
}
