import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../store';
import { TProperty } from '@type/properties';

interface FilterState {
	data: Partial<Record<keyof TProperty, string | number | number[]>>,
	status: "idle" | "loading" | "failed"
}

const initialState: FilterState = {
	data: {},
	status: 'idle',
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		filtersUpdate: (
			state,
			action: PayloadAction<{}>
		) => {
			Object.assign(state.data, action.payload)
		},
		filtersReset: (
			state
		) => {
			state.data = {}
		}
	}
});
export const selectFilters = (state: RootState) => {
	return state.filters;
}

export const { filtersReset, filtersUpdate } = filtersSlice.actions;

export default filtersSlice.reducer;
