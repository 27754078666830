import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { hydrateRoot, createRoot } from 'react-dom/client';
import { UIAndRoutes } from './ssr';
//@ts-ignore
const container = document.getElementById("root");
import { LayoutMain } from '@ui/layouts/main';
import { Provider } from "react-redux";
import { store } from './store';

createRoot(container!).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <LayoutMain>
                    <UIAndRoutes />
                </LayoutMain>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);