import { fetchPropertiesAsync, selectProperties } from '@api/Properties/slice';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from './layout';
import { ActionPrompt } from '@ui/components/ActionPrompt';
const PropertiesTable = () => {
    const { data, status } = useAppSelector(selectProperties);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(data).length !== 0) return
        dispatch(fetchPropertiesAsync());
    }, [])

    if (status == "loading") {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <AdminLayout showBackButton={false}>
            <Grid container sx={{ justifyContent: "end" }} spacing={2}>
                <Grid item>
                    <Button onClick={() => navigate("properties")} variant="contained">Create New</Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Area of Town</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Featured</TableCell>
                                    <TableCell>Published</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    Object.values(data).sort(function ({ featured: x }, { featured: y }) {
                                        return (x === y) ? 0 : x ? -1 : 1;
                                    }).map(({ slug, name, areaOfTown, status, propertyType, featured, published }) =>
                                        <TableRow key={slug} sx={{ backgroundColor: published ? "unset" : "lightyellow" }}>
                                            <TableCell component="th" scope="row">{name}</TableCell>
                                            <TableCell component="th" scope="row">{areaOfTown}</TableCell>
                                            <TableCell component="th" scope="row">{propertyType}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {status && <Chip label={status} color={status === "Rental" ? "info" : "primary"} />}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Switch disabled defaultChecked={featured}></Switch>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Switch disabled defaultChecked={published}></Switch>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Button startIcon={<Edit />} onClick={() => navigate(`properties/${slug}`)} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <ActionPrompt
                                                            title={<>Delete <b>{name}</b>?</>}
                                                            buttonProps={{ startIcon: <Delete /> }}
                                                            onContinue={async () => {
                                                                await fetch(`/admin/api/property/${slug}`, {
                                                                    method: "DELETE"
                                                                })
                                                                dispatch(fetchPropertiesAsync());
                                                            }}
                                                        >
                                                            This is permanent and will also remove any images associated with it.
                                                        </ActionPrompt>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </AdminLayout>
    )
}

export const Admin = () => {
    return (
        <PropertiesTable />
    )
}

export default Admin