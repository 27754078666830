import { AppBar, Box, Button, ButtonGroup, Grid, IconButton, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { ButtonLink } from '@ui/components/ButtonLink';
import { theme } from '@ui/theme';
import React from 'react';
import config from '@ui/config.json';
import { SearchInput } from '@ui/components/SearchInput';
import { SwitchLanguage } from '@ui/components/LanguageSwitch';
import { Link } from 'react-router-dom';
import { Email, EmailOutlined, Facebook, Instagram, Map, Phone } from '@mui/icons-material';


export const LayoutMain: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <AppBar color="transparent">
                <Toolbar variant="regular" sx={{backgroundColor: "white"}}>
                  <Box>
                    <Link to="/"><img src="/assets/logo.png" height="50px" /></Link>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box>
                    <ButtonGroup variant="text">
                      <Button href='/contact'>Contact</Button>
                      <Button><EmailOutlined/></Button>
                    </ButtonGroup>
                  </Box>
                  <Box>
                    <SwitchLanguage/>
                  </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column"}}>
              <Box className="content" sx={{
                width: "100%",
                flex: 1
              }}>
                <Toolbar />
                {children}
              </Box>
              <Box sx={{
                flex: 0,
                marginTop: "20px",
                width: "100%",
                backgroundColor: "primary.main"
              }}>
                <Grid container sx={{padding: "20px", color: "white"}}>
                  <Grid xs={12} sm={12} md={4} item sx={{padding: "10px"}} container>
                    <Grid item xs={12}><center><img src="/assets/logo_white.png" height="50px"/></center></Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}><span>Copyright 2024</span></Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}><span>All Rights Reserved</span></Grid>
                  </Grid>
                  <Grid container sm={6} xs={12} md={4} item>
                    <Button disableRipple disableTouchRipple sx={{ color: "white", alignItems: "baseline", textTransform: "capitalize", textAlign: "left"}} startIcon={<Box sx={{top: "7px", position: "relative"}}><Map/></Box>}>Oficina 65 planta alta,<br/> Comercial Plaza Caracol, Centro<br/> Puerto Vallarta, Jalisco, Mex</Button>
                  </Grid>
                  <Grid xs={12} sm={6} md={4} item container>
                    <Grid item xs={12} container>
                      <Grid item xs={12}> 
                        <ButtonGroup orientation="vertical" sx={{alignItems: "baseline"}} disableElevation>
                          { config.contact.email.map((address) => <Button key={address} href={`mailto:${address}`} startIcon={<Email />} sx={{color: "white", textTransform: "lowercase"}}>{address}</Button>) }
                          <Button href={`tel:${config.contact.phone}`} startIcon={<Phone />} sx={{color: "white"}}>Cell: {config.contact.phoneLabel}</Button>
                          <Button href={`tel:${config.contact.phoneOffice}`} startIcon={<Phone />} sx={{color: "white"}}>Office: {config.contact.phoneOfficeLabel}</Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid xs={12} item>
                        <ButtonGroup>
                          <IconButton target="_social" href={config.contact.facebook} sx={{color:"white"}}><Facebook/></IconButton>
                          <IconButton target="_social" href={config.contact.instagram} sx={{color:"white"}}><Instagram/></IconButton>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
        </ThemeProvider>
    )
}