import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Divider, Skeleton, Typography } from '@mui/material';
import { TProperty } from '@type/properties';
import { USDollar, MXPeso } from '@ui/helpers';
import BedIcon from '@mui/icons-material/Bed';
import React from 'react';
import { ShowerOutlined, Straighten } from '@mui/icons-material';
import { Link } from 'react-router-dom';
type CardPropertyProps = Omit<TProperty, "address" | "lat" | "lon"> & { loading?: boolean };


export const CardProperty = ({ thumbnail = {}, name, areaOfTown, price, currency, propertyType, bedrooms, lotAreaSquareMeters, bathrooms, featured, images = [], slug, status: propertyStatus }: CardPropertyProps) => {
	const frontCardImageId = thumbnail.cf_id || images[0]?.cf_id || "";
	return (
		<Link to={`/property/${slug}`} style={{ textDecoration: "none" }}>
			<Card sx={{ overflow: "unset", margin: "10px", height: "100%" }} elevation={5}>
				<CardMedia
					sx={{ height: 220, position: "relative", borderTopRightRadius: "3px", borderTopLeftRadius: "3px", backgroundColor: "white" }}
					image={`https://imagedelivery.net/NMyjuGrCZRR8eWvVmRVwqg/${frontCardImageId}/frontcard`}
					title={thumbnail.cf_id}
				>
					{(price || propertyStatus) && <Box color="white" sx={{
						padding: "13px 23px 13px 15px",
						backgroundColor: "primary.main",
						display: "inline-block",
						position: "absolute",
						top: "10px",
						left: "-5px",
						borderRadius: "2px",
						borderBottomRightRadius: "30px"
					}}>
						<Typography sx={{ "& span": { fontSize: "0.7em" } }}>{propertyStatus && propertyStatus} {price && USDollar.format(price)} <span>{currency}</span></Typography>
					</Box>
					}
					{featured && <Box color="white" sx={{
						position: "absolute",
						bottom: "10px",
						right: "-5px",
						backgroundColor: "secondary.main",
						padding: "5px 10px",
						borderRadius: "5px"
					}}>Featured</Box>}
				</CardMedia>
				<CardContent>
					<Typography variant="h5" component="div">{name}</Typography>
					<Typography variant="subtitle1" color="text.secondary" component="div" gutterBottom>{areaOfTown}&nbsp;</Typography>
					<Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>{propertyType}&nbsp;</Typography>
					<Divider />
				</CardContent>
				<CardActions>
					{bedrooms && <Button disabled aria-label="Bed" variant="text" startIcon={<BedIcon />}>{bedrooms}</Button>}
					{bathrooms && <Button disabled aria-label="Bath" variant="text" startIcon={<ShowerOutlined />}>{bathrooms}</Button>}
					{lotAreaSquareMeters && <Button disabled aria-label="Measurement" variant="text" startIcon={<Straighten />}>{lotAreaSquareMeters}M<span style={{
						verticalAlign: "super"
					}}>2</span></Button>}
				</CardActions>
			</Card>
		</Link>
	)
}
