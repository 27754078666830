import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { LoadingButton } from "@mui/lab";
import { Check, Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ActionPrompt = ({ buttonProps, text, title, children, onContinue, onCancel, type = "Button" }: React.PropsWithChildren<{
    title?: string | React.ReactElement;
    text?: string;
    buttonProps?: React.ComponentProps<typeof Button>
    onContinue: () => Promise<void>
    onCancel?: () => Promise<void>
    type?: "Button" | "IconButton"
}>) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setLoading(true);
        onCancel && await onCancel();
        setLoading(false);
        setOpen(false);
    };
    return (
        <React.Fragment>
            {type === "Button" ? <Button onClick={handleClickOpen} {...buttonProps}>{text}</Button> : <IconButton onClick={handleClickOpen} {...buttonProps}>{buttonProps?.startIcon}</IconButton>}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{children}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={!loading && <Close />} disabled={loading} onClick={handleClose}>Cancel</Button>
                    <LoadingButton startIcon={<Check />} loading={loading} loadingPosition="start" disabled={loading} onClick={async () => {
                        setLoading(true);
                        await onContinue();
                        handleClose();
                    }}>Continue</LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}