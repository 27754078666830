import { Button, Card, Grid, TextField, MenuItem, FormControlLabel, Switch, InputAdornment, CardContent, CardActionArea, CardActions, IconButton, CardMedia, CircularProgress, Skeleton, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminLayout } from '../../layout';
import { Formik } from 'formik';

import { Property, PropertyOptions } from '@type/properties';
import { z } from 'zod';

import { BlockTypeSelect, BoldItalicUnderlineToggles, InsertTable, InsertThematicBreak, ListsToggle, MDXEditor, UndoRedo, headingsPlugin, listsPlugin, tablePlugin, thematicBreakPlugin, toolbarPlugin } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchPropertiesAsync, selectProperties } from '@api/Properties/slice';
import { AddPhotoAlternate, ChevronLeft, ChevronRight, Delete, PlusOne, UploadFile } from '@mui/icons-material';
import { ActionPrompt } from '@ui/components/ActionPrompt';
import axios from 'axios';


interface ImageUploaderProps {
	slug?: string;
	cf_id?: string
}

const ImageUploader = ({ cf_id, slug }: ImageUploaderProps) => {
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const dispatch = useAppDispatch();

	const handleFileDelete = async (cf_id: string) => {
		await axios.delete(`/admin/api/property/${slug}/image/${cf_id}`)
		dispatch(fetchPropertiesAsync(slug))
	}

	const handleFileSelect = async (event) => {
		setLoading(true)
		const resp = await fetch(`/admin/api/property/${slug}/image`, {
			method: "POST"
		})
		const { uploadURL } = await resp.json<{ uploadURL: string }>();
		const formData = new FormData();
		formData.append("file", event.target.files[0]);
		await axios.post(uploadURL, formData, {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let precentage = Math.floor((loaded * 100) / total);
				setProgress(precentage);
			},
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		dispatch(fetchPropertiesAsync(slug))
		setLoading(false)
	}
	return (
		<Card variant="outlined" sx={{ height: "200px", width: "200px" }}>
			{(cf_id && !loading) && <CardMedia
				sx={{ height: 140 }}
				image={`https://imagedelivery.net/NMyjuGrCZRR8eWvVmRVwqg/${cf_id}/thumbnail`}
			/>}
			{(!cf_id && loading) && <Skeleton variant="rectangular" animation="wave" width={"100%"} height={140} />}
			{(!cf_id && !loading) && <CardContent sx={{ justifyContent: "center", display: "flex", marginTop: "60px" }}>
				<input accept=".png,.jpg,.jpeg" type="file" id="upload-new-image" hidden onChange={handleFileSelect} />
				<label htmlFor="upload-new-image" style={{ cursor: "pointer" }}><AddPhotoAlternate fontSize="large" /></label>
			</CardContent>}
			<CardActions sx={{ justifyContent: "space-evenly" }}>
				{cf_id && <IconButton><ChevronLeft /></IconButton>}
				{loading && <CircularProgress variant="determinate" value={progress} />}
				{cf_id &&
					<ActionPrompt title="Delete Image?" type="IconButton" buttonProps={{
						startIcon: <Delete />
					}}
						onContinue={async () => {
							handleFileDelete(cf_id)
						}}>
						This action is permanent
					</ActionPrompt>
				}
				{cf_id && <IconButton><ChevronRight /></IconButton>}
			</CardActions>
		</Card>
	)
}

export const EditOrCreate = () => {
	const { slug = undefined } = useParams();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(fetchPropertiesAsync(slug))
	}, [])
	const { data = {} } = useAppSelector(selectProperties);
	const initialValues: Partial<z.infer<typeof Property>> = slug && data[slug] ? data[slug] : {}
	const navigate = useNavigate();
	return (
		<AdminLayout>
			<Formik
				key={(slug && data[slug])?.toString()}
				initialValues={initialValues}
				validate={values => {
					try {
						Property.omit({ id: true }).parse(values)
					} catch (e) {
						if (e instanceof z.ZodError) {
							return e.formErrors.fieldErrors
						}
					}
				}}
				onSubmit={async (values, { setSubmitting, setTouched }) => {
					await fetch(slug ? `/admin/api/property/${slug}` : "/admin/api/property", {
						method: slug ? "PATCH" : "POST",
						body: JSON.stringify(values)
					})
					setSubmitting(false);
					setTouched({})
					dispatch(fetchPropertiesAsync(slug))
					navigate((values.name || "").toString().replaceAll(" ", "-").toLowerCase())
				}}
			>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					setFieldTouched,
					handleSubmit,
					setFieldValue,
					touched,
					isSubmitting,
					resetForm
				}) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={5} sx={{ justifyContent: "end" }}>
							<Grid item>
								{slug && <Button onClick={() => navigate(`/property/${slug}`)} variant="contained">Preview</Button>}
							</Grid>
							<Grid item xs={12} />
							<Grid item>
								<FormControlLabel
									control={<Switch defaultChecked={values.featured} />}
									label="Featured"
									name="featured"
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									control={<Switch defaultChecked={values.published} />}
									label="Published"
									name="published"
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Grid>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Grid container gap={1}>
											{((slug && data[slug] && data[slug].images) || []).map(({ cf_id }) => <Grid item><ImageUploader slug={slug} cf_id={cf_id} /></Grid>)}
											<Grid item>{slug && data[slug] ? <ImageUploader slug={slug} /> : <><div style={{ pointerEvents: "none", opacity: 0.8 }}><ImageUploader slug={slug} /></div><Alert sx={{ marginTop: "10px" }} severity="warning">Save the property before upload images</Alert></>}</Grid>

										</Grid>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									error={errors.name !== undefined}
									label="Name of House"
									name="name"
									helperText={errors.name}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.name}
									placeholder="Casa Azul"
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									fullWidth
									required
									select
									label="Area of Town"
									name="areaOfTown"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.areaOfTown}
									error={errors.areaOfTown !== undefined}
									helperText={errors.areaOfTown}
								>
									{
										PropertyOptions.areaOfTown.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))
									}
								</TextField>
							</Grid>
							<Grid item xs={4}>
								<TextField
									fullWidth
									required
									select
									label="Type"
									name="propertyType"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.propertyType}
									error={errors.propertyType !== undefined}
									helperText={errors.propertyType}
								>
									{
										PropertyOptions.propertyType.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))
									}
								</TextField>
							</Grid>
							<Grid item xs={4}>
								<TextField
									fullWidth
									required
									select
									label="Status"
									name="status"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.status}
									error={errors.status !== undefined}
									helperText={errors.status}
								>
									{
										PropertyOptions.status.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))
									}
								</TextField>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									required
									select
									label="Currency"
									name="currency"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.currency}
									error={errors.currency !== undefined}
									helperText={errors.currency}
								>
									{
										PropertyOptions.currency.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))
									}
								</TextField>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									required
									error={errors.price !== undefined}
									label="Price"
									name="price"
									type="number"
									helperText={errors.price}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.price}
									placeholder="100000"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									required
									error={errors.bedrooms !== undefined}
									label="Bedrooms"
									name="bedrooms"
									type="number"
									helperText={errors.bedrooms}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.bedrooms}
									placeholder="3"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									required
									error={errors.bathrooms !== undefined}
									label="Bathrooms"
									name="bathrooms"
									type="number"
									helperText={errors.bathrooms}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.bathrooms}
									placeholder="3"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									error={errors.lat !== undefined}
									label="Latitude"
									name="lat"
									type="number"
									helperText={errors.lat}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.lat}
									placeholder="20.602791"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									error={errors.lon !== undefined}
									label="Longitude"
									name="lon"
									type="number"
									helperText={errors.lon}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.lon}
									placeholder="-105.233719"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									fullWidth
									error={errors.lotAreaSquareMeters !== undefined}
									label="Lot Area"
									name="lotAreaSquareMeters"
									type="number"
									helperText={errors.lotAreaSquareMeters}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.lotAreaSquareMeters}
									placeholder="1000"
									InputProps={{
										endAdornment: <InputAdornment position="end">m2</InputAdornment>,
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									fullWidth
									error={errors.constructionAreaSquareMeters !== undefined}
									label="Construction Area"
									name="constructionAreaSquareMeters"
									type="number"
									helperText={errors.constructionAreaSquareMeters}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.constructionAreaSquareMeters}
									placeholder="1000"
									InputProps={{
										endAdornment: <InputAdornment position="end">m2</InputAdornment>,
									}}
								/>
							</Grid>
							<Grid item xs={7}>
								<TextField
									fullWidth
									required
									error={errors.address !== undefined}
									label="Address"
									name="address"
									helperText={errors.address}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.address}
								/>
							</Grid>
							<Grid item xs={12}>
								<Card sx={errors.description ? {
									border: "1px solid rgb(211, 47, 47)"
								} : {}}>
									<MDXEditor
										onChange={(value) => {
											setFieldValue("description", value)
											setFieldTouched("description", true)
										}}
										markdown={values.description || ""} plugins={[
											headingsPlugin(),
											tablePlugin(),
											listsPlugin(),
											thematicBreakPlugin(),
											toolbarPlugin({
												toolbarContents: () => (
													<>
														<BlockTypeSelect />
														<BoldItalicUnderlineToggles />
														<UndoRedo />
														<InsertTable />
														<ListsToggle />
														<InsertThematicBreak />
													</>
												)
											})]} />
								</Card>
								{errors.description && <div style={{
									color: "rgb(211, 47, 47)",
									fontFamily: "Roboto, Helvetica, Arial, sans-serif",
									fontSize: "0.75rem",
									marginTop: "8px",
									marginLeft: "10px"
								}}>{errors.description}</div>}
							</Grid>
							<Grid item xs={12} container sx={{ justifyContent: "space-between" }}>
								<Button disabled={isSubmitting || Object.keys(touched).length == 0} variant="contained" onClick={() => resetForm()}>
									Reset
								</Button>
								<Button disabled={isSubmitting} variant="contained" type="submit">
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</AdminLayout>
	)
}

export default EditOrCreate;
