import { OpenAPIRoute, Query, Str } from "@cloudflare/itty-router-openapi";
import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import {
	Routes,
	Route,
	Link
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from '../store';
import { Env } from "..";
import Home from "@ui/pages/home";
import Admin from "@ui/pages/admin";
import PageProperty from "@ui/pages/property";
import EditOrCreate from "@ui/pages/admin/routes/properties";
import { TProperty } from "@type/properties";

export const UIAndRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={
				<Home />
			} />
			<Route path="/property/:id" element={
				<PageProperty />
			} />
			<Route path="/admin" element={
				<Admin />
			} />
			<Route path="/admin/properties" element={<EditOrCreate />}></Route>
			<Route path="/admin/properties/:slug" element={<EditOrCreate />}></Route>
		</Routes>
	)
}

const GetSEO = async ({ request, env }: { request: Request; env: Env }): Promise<TProperty> => {
	const url = new URL(request.url);
	if (url.pathname.startsWith("/property/")) {
		const slug = url.pathname.split("/").filter(p => p !== "")[1];
		const property_record = await env.DB.prepare("SELECT * FROM Properties WHERE json_extract(raw_data, '$.slug') = ?1").bind(slug).first();
		if (property_record !== null) {
			return JSON.parse(property_record.raw_data as string) as TProperty
		}
	}
	return {} as TProperty
}

export class ReactSSR extends OpenAPIRoute {
	static schema = {
		parameters: {
			fbclid: Query(Str, { required: false })
		}
	};
	async handle(request: Request, env: Env, ctx: ExecutionContext) {
		const controller = new AbortController();

		const preloadedState = store.getState();

		const SEO = await GetSEO({ request, env });

		const App = () => {
			return (
				<React.StrictMode>
					<Provider store={store}>
						<html>
							<head>
								<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
								{SEO.name && <meta property="og:title" content={SEO.name} />}
								{SEO.id && <meta property="og:type" content="product" />}
								{SEO.images && SEO.images[0] && <meta property="og:image" content={`https://imagedelivery.net/NMyjuGrCZRR8eWvVmRVwqg/${SEO.images[0].cf_id}/frontcard`} />}
								{SEO.description && <meta property="og:description" content={SEO.description.split("").slice(0, 197).join("") + "..."} />}
								{SEO.content && <meta property="og:content" content={SEO.content.split("").slice(0, 197).join("") + "..."} />}
								{SEO.price && <meta property="product:price.amount" content={SEO.price.toString()} />}
								{SEO.currency && <meta property="product:price.currency" content={SEO.currency} />}
								<style>
									{`
                                    html, body, #root {
                                        height: 100%;
                                        width: 100%;
                                        margin: 0px;
                                    }
                                    `}
								</style>
								<link rel="stylesheet" href="/scripts/index.client.css" />
							</head>
							<body>
								<div id="root">
									{/*
                                        <StaticRouter location={new URL(request.url).pathname}>
                                            <LayoutMain>
                                                <UIAndRoutes />
                                            </LayoutMain>
                                        </StaticRouter>
                                    */}
								</div>
								<script dangerouslySetInnerHTML={{
									__html: `
									window.__PRELOADED_STATE__ = ${JSON.stringify(preloadedState, null, 2).replace(
										/</g,
										'\\u003c'
									)}
								`}}>
								</script>
								<script type="module" src="/scripts/index.client.js" />
							</body>
						</html>
					</Provider>
				</React.StrictMode>
			)
		}
		const stream = await ReactDOMServer.renderToReadableStream(
			<App />,
			{
				signal: controller.signal,
				onError(error) {
					console.error(error);
				}
			}
		);

		return new Response(stream, {
			status: /*Check404.includes("<meta name=\"404\"\/>") ? 404 : */200,
			headers: { 'Content-Type': 'text/html', 'Content-Security-Policy': 'frame-ancestors docs.google.com', 'X-Frame-Options': 'ALLOW-FROM docs.google.com' },
		});
	}
}
