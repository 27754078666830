import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchProperties } from './api';
import { PropertiesState } from '@type/properties';

const initialState: PropertiesState = {
  data: {},
  status: 'idle',
};

export const fetchPropertiesAsync = createAsyncThunk(
  'properties/fetch',
  async (slug?: string) => {
    return await fetchProperties(slug);
  }
);

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPropertiesAsync.fulfilled, (state, action) => {
        Object.assign(state.data, action.payload);
        state.status = 'idle';
      })
      .addCase(fetchPropertiesAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectProperties = (state: RootState) => {
  return state.properties;
}

export default propertiesSlice.reducer;