import { Email, Facebook, Share, Twitter, WhatsApp } from '@mui/icons-material';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import React from 'react';

export const ButtonShare = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<Share />}
                variant="text"
            >
                Share
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
                }}>
                    <ListItemIcon><Facebook /></ListItemIcon>
                    Facebook
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); window.open(`http://www.twitter.com/share?url=${window.location.href}`) }}>
                    <ListItemIcon><Twitter /></ListItemIcon>
                    Twitter
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); window.open(`mailto:pvrone@gmail.com?body=${window.location.href}`) }}>
                    <ListItemIcon><Email /></ListItemIcon>
                    Email
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); window.open(`https://wa.me/523222299247/?text=${window.location.href}`) }}>
                    <ListItemIcon><WhatsApp /></ListItemIcon>
                    Whatsapp
                </MenuItem>
            </Menu>
        </div>
    )
}