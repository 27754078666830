import { ChevronLeft } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AdminLayout = ({ children, showBackButton = true }: React.PropsWithChildren<{ showBackButton?: boolean }>) => {
    const navigate = useNavigate();
    return (
        <Grid container padding={5}>
            <Grid item>
                {showBackButton && <Button variant="text" size="large" startIcon={<ChevronLeft />} onClick={() => navigate("/admin")}>Admin</Button>}
            </Grid>
            <Grid item xs={12} padding={5}>{children}</Grid>
        </Grid>
    )
}