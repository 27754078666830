import { TProperty } from '@type/properties';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Box, Button, ButtonGroup, Chip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Bed, Home, Map, ShowerOutlined, Straighten } from '@mui/icons-material';
import { USDollar } from '@ui/helpers';
import Markdown from 'react-markdown';
import { ButtonShare } from '@ui/components/ButtonShare';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchPropertiesAsync, selectProperties } from '@api/Properties/slice';
import { useParams } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

export const PageProperty = () => {
    const dispatch = useAppDispatch();
    const { id: slug = "" } = useParams();
    const { data, status } = useAppSelector(selectProperties);

    useEffect(() => {
        if (Object.keys(data).length !== 0) return
        dispatch(fetchPropertiesAsync(slug))
    }, [])

    if (!data[slug]) {
        return <></>
    }

    const { images = [], name, areaOfTown, currency, price, bedrooms, bathrooms, propertyType, lotAreaSquareMeters, description, content, status: propertyStatus } = data[slug];

    return (
        <Box sx={{
            "& .swiper-button-next, .swiper-button-prev": {
                color: "#E4352E"
            }
        }}>
            <Swiper
                modules={[Navigation]}
                navigation={true}
                loop
                slidesPerView={"auto"}
            >
                {images.map(({ cf_id }) => (
                    <SwiperSlide key={cf_id}>
                        <Grid container sx={{
                            height: "50vmin",
                            width: "100vw",
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                        }}>
                            <Grid sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                zIndex: "-1",
                                overflow: "hidden"
                            }}>
                                <div style={{
                                    backgroundImage: `url('https://imagedelivery.net/NMyjuGrCZRR8eWvVmRVwqg/${cf_id}/public')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    filter: "blur(5px)",
                                    width: "120%",
                                    height: "120%",
                                    left: "-10%",
                                    top: "-10%",
                                    position: "relative"
                                }} />
                            </Grid>
                            <Grid xs={12} sx={{
                                height: "100%",
                                backgroundImage: `url('https://imagedelivery.net/NMyjuGrCZRR8eWvVmRVwqg/${cf_id}/public')`,
                                backgroundSize: 'contain',
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center"
                            }} />
                        </Grid>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Grid container sx={{ justifyContent: "center", marginTop: "20px", "& button": { borderRight: "none !important" } }}>
                <Grid xs={11} md={8} container>
                    <Grid container sx={{ flexGrow: 1 }}>
                        <Grid sx={{ display: "flex", alignItems: "center" }}><Typography fontSize={"2.5em"}>{name.toUpperCase()}{propertyStatus && <Chip size="small" color="primary" sx={{ marginLeft: "15px", marginBottom: "10px" }} label={propertyStatus} />}</Typography></Grid>
                        <Grid smOffset="auto"></Grid>
                        <Grid><ButtonShare /></Grid>
                    </Grid>
                    <Grid xs={12}>
                        <ButtonGroup variant="text" disabled>
                            {price && <Button size="large" sx={{ alignItems: "baseline" }}><Typography fontSize={"2em"}>{USDollar.format(price)}</Typography><Typography fontSize={"1em"}>{currency}</Typography></Button>}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={12}>
                        <ButtonGroup variant="text" disabled>
                            {areaOfTown && <Button startIcon={<Map />}>{areaOfTown}</Button>}
                        </ButtonGroup>
                        <ButtonGroup variant="text" disabled>
                            {propertyType && <Button startIcon={<Home />}>{propertyType}</Button>}
                            {bedrooms && <Button startIcon={<Bed />}>{bedrooms} Bed</Button>}
                            {bathrooms && <Button startIcon={<ShowerOutlined />}>{bathrooms} Bath</Button>}
                            {lotAreaSquareMeters && <Button startIcon={<Straighten />}>{lotAreaSquareMeters}M<span style={{ verticalAlign: "super" }}>2</span></Button>}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <Markdown skipHtml>{content || description}</Markdown>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PageProperty;