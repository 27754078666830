import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { propertiesSlice } from './api/Properties/slice';
import { filtersSlice } from './api/Properties/filters.slice';

const rootReducer = combineReducers({
	[propertiesSlice.name]: propertiesSlice.reducer,
	[filtersSlice.name]: filtersSlice.reducer
})

export const store = configureStore({
	reducer: rootReducer,
	preloadedState: (globalThis as any).__PRELOADED_STATE__ || {},
	devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
