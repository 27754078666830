import { AppBar, Box, Button, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Toolbar } from '@mui/material';
import type { FormControlOwnProps, SelectProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { PropertyOptions, TProperty } from '@type/properties';
import { CardProperty } from '@ui/components/PropertyCard';
import { SearchInput } from '@ui/components/SearchInput';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchPropertiesAsync, selectProperties } from '@api/Properties/slice';
import { filtersReset, filtersUpdate, selectFilters } from '@api/Properties/filters.slice';

const PriceRanges = [
	"$0 - $100,000",
	"$100,000 - $150,000",
	"$150,000 - Above"
] as const
const BedsRange = [
	"1",
	"2",
	"4",
	"5+"
]

type SearchControlProps = {
	options: string[];
	name: keyof TProperty;
	sx?: FormControlOwnProps["sx"];
} & Pick<SelectProps, "onChange" | "label">


export const Home = () => {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectProperties);
	const { data: filters } = useAppSelector(selectFilters);

	useEffect(() => {
		if (Object.keys(data).length !== 0) return
		dispatch(fetchPropertiesAsync());
	}, [])

	const getPriceArray = (price: typeof PriceRanges[number]) => {
		return price.replaceAll(" ", "").replaceAll("$", "").replaceAll(",", "").split("-").map(price => parseFloat(price))
	}

	const onChange = (name: keyof TProperty, value: string | number) => {
		if (name === "price" && typeof value == "string") {
			dispatch(filtersUpdate({
				[name]: getPriceArray(value as typeof PriceRanges[number])

			}))
			return;
		}
		dispatch(filtersUpdate({
			[name]: value
		}))
	}

	const handleReset = () => {
		dispatch(filtersReset());
	}

	const SearchControl = ({ options, label, name, sx }: SearchControlProps) => {
		const value = name == "price" ? PriceRanges[PriceRanges.map(p => getPriceArray(p).toString()).indexOf((filters[name] || "").toString())] : (filters[name] || "");
		return (
			<FormControl sx={{ width: "100%", ...sx }} size="small">
				<InputLabel>{label}</InputLabel>
				<Select label={label} value={value} onChange={(event) => onChange(name, event.target.value as string | number)}>
					{
						options.map(name => (
							<MenuItem color="white" value={name} key={name}>{name}</MenuItem>))
					}
				</Select>
			</FormControl>
		)
	}

	return (
		<Box sx={{ position: "relative" }}>
			<Box sx={{ height: "40vh" }} />
			<Box sx={{
				height: "50vh",
				position: "absolute",
				backgroundImage: "url('/assets/sample-images/home.png')",
				backgroundSize: "cover",
				backgroundPosition: "center",
				top: "0px",
				width: "100%"
			}}></Box>
			<Grid position="sticky" sx={{ top: "64px", zIndex: 1090, backgroundColor: "white", justifyContent: "right", }} container padding={1}>
				<Grid item xs={12} md={2} sx={{ padding: 1 }}>
					<SearchControl name="areaOfTown" label="Area" options={[...PropertyOptions.areaOfTown]} />
				</Grid>
				<Grid item xs={6} md={2} sx={{ padding: 1 }}>
					<SearchControl name="propertyType" label="Building Type" options={[...PropertyOptions.propertyType]} />
				</Grid>
				<Grid item xs={6} md={1} sx={{ padding: 1 }}>
					<SearchControl name="bedrooms" label="Beds" options={BedsRange} />
				</Grid>
				<Grid item xs={3} md={1} sx={{ padding: 1 }}>
					<SearchControl name="status" label="Type" options={[...PropertyOptions.status]} />
				</Grid>
				<Grid item xs={4} md={2} sx={{ padding: 1 }}>
					<SearchControl name="currency" label="Currency" options={[...PropertyOptions.currency]} />
				</Grid>
				<Grid item xs={5} md={2} sx={{ padding: 1 }}>
					<SearchControl name="price" label="Price Range" options={[...PriceRanges]} />
				</Grid>
				<Grid item xs={4} md={2} sx={{ padding: 1 }}>
					<Button onClick={handleReset} variant="contained" sx={{ width: "100%" }}>Reset</Button>
				</Grid>
			</Grid>
			<Box sx={{ height: "30px" }} />
			<Grid container sx={{ rowGap: "20px" }}>
				{Object.entries(data).filter(([_slug, { published }]) => published).filter(([_slug, property]) => {
					return !Object.entries(filters).map(([field, condition]) => {
						const value = property[field as keyof TProperty];
						switch (typeof condition) {
							case "string":
								if (isNaN(parseFloat(condition))) {
									if (condition.indexOf("+") === -1) {
										return value === condition;
									}
									return value ? (value as number) >= parseFloat(condition.replace("+", "")) : false
								} else {
									return value ? (value as number) === parseFloat(condition) : false
								}
								break;
							case "object":

								if (isNaN(condition[1])) {
									return value ? (value as number) >= condition[0] : false
								}
								return value ? (value as number) >= condition[0] && (value as number) <= condition[1] : false
								break;
							default:
								return false;
						}
					}).includes(false)
				}).sort(function([ind1, { featured: x }], [ind2, { featured: y }]) {
					return (x === y) ? 0 : x ? -1 : 1;
				}).map(([index, property]) => (
					<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
						<CardProperty
							{...property}
						/>
					</Grid>
				))}

			</Grid>
		</Box>
	)
}

export default Home;
